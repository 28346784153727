<template>
    <div>
        <a-card class="table">
        <a-table
          :columns="columns"
          :data-source="data"
        >
        </a-table>
      </a-card>
    </div>
</template>
<script>
import {
  getSystemStatus,
} from "@/api/system/system.js";

const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "描述",
    dataIndex: "description",
    key: "description",
    scopedSlots: { customRender: "description" },
  },
  {
    title: "值",
    dataIndex: "value",
    key: "value",
    scopedSlots: { customRender: "value" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
    };
  },
  methods: {
    refreshStatus() {
      getSystemStatus().then((res) => {
        this.data = res.data.metrics.metric.map((item) => {
          item.key = item.name;
          return item;
        });
      });
    },

  },
  mounted() {
    this.refreshStatus();
  },
};
</script>

<style scoped>
</style>